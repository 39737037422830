export const removeHtmlTags = (str) => {
    if (!str) return ''; 
    return str.replace(/<[^>]*>/g, '');
  };
export const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) {
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return 'Invalid Date';
  };
  export const truncateText = (text, type = 'title') => {
    const maxLength = type === 'title' ? 45 : 70; // Title ke liye max length 45, description ke liye 70
  
    if (!text) return ''; // Agar text nahi diya gaya, toh empty string return karo
  
    // Agar text ka length max limit se zyada ho, toh truncate kar do aur "..." add kar do
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
  
    return text; 
  };
  