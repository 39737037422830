import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Card,
    CardMedia,
    CardContent,
    Container,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
// Base URL for API
const BASE_URL = "https://echtzeitnachrichten.de";
// https://echtzeitnachrichten.de/admin/api/all/news_writer
// Utility functions
const removeHtmlTags = (str) => {
    if (!str) return "";
    return str.replace(/<[^>]*>/g, "");
};

const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) {
        return formattedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }
    return "Invalid Date";
};
const truncateText = (text, type = "title") => {
    const maxLength = type === "title" ? 15 : 70;
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
const fetchLatestNews = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/admin/api/latest/news`);
        return response.data.news || [];
    } catch (error) {
        console.error("Error fetching news:", error);
        return [];
    }
};

const HeroSection = () => {
    const [latestNews, setLatestNews] = useState([]);
    const [featuredArticle, setFeaturedArticle] = useState(null);

    useEffect(() => {
        const fetchNewsData = async () => {
            const newsData = await fetchLatestNews();
            if (newsData.length > 0) {
                setFeaturedArticle(newsData[0]);
                setLatestNews(newsData.slice(1, 4));
            }
        };
        fetchNewsData();
    }, []);

    return (
        <Box sx={{ padding: "40px 0px", backgroundColor: "#f9f9f9", borderTop: "0.5px solid gray" }}>
            <Container>
                {/* Heading */}
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                        padding: "0px 16px",
                        fontWeight: "bold",
                        mb: 2,
                        fontSize: "22px",
                        margin: "30px 0px",
                        position: "relative",
                        paddingLeft: "16px",
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "4px",
                            height: "100%",
                            backgroundColor: "red", // Changed to red
                            borderRadius: "3px",
                        },
                        color: "red", // Changed text color to red
                    }}
                >
                    Neueste Nachrichten
                </Typography>


                <Grid container spacing={3}>
                    {/* Left Sidebar: Latest News */}
                    <Grid item xs={12} md={3}>
                        {latestNews.map((news, index) => (
                            <Card
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    marginBottom: "15px",
                                    height: "120px", // Ensures consistent card height
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": { transform: "scale(1.02)" },
                                }}
                            >
                                <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>

                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: "100px", // Consistent image width
                                            height: "100px", // Consistent image height
                                            borderRadius: "8px",
                                            objectFit: "cover", // Ensures images maintain aspect ratio
                                            marginRight: "10px",
                                        }}
                                        image={news.image || "https://via.placeholder.com/150"}
                                        // alt={news.title}
                                    />
                                </Link>
                                <CardContent
                                    sx={{
                                        padding: 0,
                                        overflow: "hidden", // Prevents text overflow
                                    }}
                                >
                                    <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>

                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                                lineHeight: "1.5em",
                                                color: "#333", // Darker color for better contrast
                                            }}
                                        >
                                            {truncateText(news.title, "title")}
                                        </Typography>
                                    </Link>
                                    <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: "gray",
                                                textTransform: "uppercase",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {news.category}

                                        </Typography>
                                    </Link>


                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                                    >
                                        <Link to={`/writer/${news.writerName}`} style={{ textDecoration: 'none' }}>
                                        Von {news.writerName}
                                        </Link>  • {formatDate(news.date)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>

                    {/* Main Hero Section */}
                    <Grid item xs={12} md={6}>
                        {featuredArticle && (
                            <Card
                                sx={{
                                    boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                                    borderRadius: "12px",
                                    overflow: "hidden",
                                    height: "100%",
                                }}
                            >
                                <Link to={`/${featuredArticle.slug}`} style={{ textDecoration: 'none' }}>

                                    <CardMedia
                                        component="img"
                                        image={featuredArticle.image || "https://via.placeholder.com/300"}
                                        alt={featuredArticle.title}
                                        sx={{
                                            height: "300px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Link>
                                <CardContent>
                                    <Link to={`/${featuredArticle.slug}`} style={{ textDecoration: 'none' }}>

                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: "22px",
                                                // marginTop: "10px",
                                                lineHeight: "1.4em", // Increased line spacing for better readability
                                                color: "#212121",
                                            }}
                                        >
                                            {truncateText(featuredArticle.title, "title")}
                                        </Typography>
                                    </Link>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "gray",
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {featuredArticle.category}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ fontSize: "12px", marginTop: "4px" }}
                                    >
                                        <Link to={`/writer/${featuredArticle.writerName}`} style={{ textDecoration: 'none' }}>
                                        Von {featuredArticle.writerName}
                                        </Link>  • {formatDate(featuredArticle.date)}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "gray",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {truncateText(
                                            removeHtmlTags(featuredArticle.description),
                                            "description"
                                        )}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </Grid>

                    {/* Right Sidebar: Additional News */}
                    <Grid item xs={12} md={3}>
                        {latestNews.map((news, index) => (
                            <Card
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    marginBottom: "15px",
                                    height: "120px", // Ensures consistent card height
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": { transform: "scale(1.02)" },
                                }}
                            >
                                <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>

                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: "100px", // Consistent image width
                                            height: "100px", // Consistent image height
                                            borderRadius: "8px",
                                            objectFit: "cover", // Ensures images maintain aspect ratio
                                            marginRight: "10px",
                                        }}
                                        image={news.image || "https://via.placeholder.com/150"}
                                        alt={news.title}
                                    />
                                </Link>
                                <CardContent
                                    sx={{
                                        padding: 0,
                                        overflow: "hidden", // Prevents text overflow
                                    }}
                                >
                                    <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>

                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                                lineHeight: "1.5em",
                                                color: "#333", // Darker color for better contrast
                                            }}
                                        >
                                            {truncateText(news.title, "title")}
                                        </Typography>
                                    </Link>
                                    <Link to={`/${featuredArticle.slug}`} style={{ textDecoration: 'none' }}>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: "gray",
                                                textTransform: "uppercase",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {news.category}
                                        </Typography>
                                    </Link>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ fontSize: "12px", marginTop: "4px" }}
                                    >
                                        <Link to={`/writer/${featuredArticle.writerName}`} style={{ textDecoration: 'none' }}>
                                        Von {featuredArticle.writerName}
                                        </Link>  • {formatDate(news.date)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default HeroSection;
