import './App.css';
import {Home} from '../src/components/home/Home';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import SinglePage from './components/singlePage/SinglePage';
import PostDetail from './components/newsdetail/NewsDetail';
import WriterPage from './components/home/WriterPage';
import Footer from './components/footer/Footer';
import About from './components/footer/About';
import Contact from './components/footer/Contact';
import PrivacyPolicy from '../src/components/footer/PrivacyPolicy';
import ScrollToTop from './components/footer/ScrollToTop';
import SearchResults from './components/home/SearchComponent';
import AllNews from './components/home/AllNews';
function App() {
  return (
    <BrowserRouter>
    <ScrollToTop /> 
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
      <Route path="/categoría/:category" element={<SinglePage />} />
         <Route path="/:slug" element={<PostDetail />} />
         <Route path="/writer/:writerName" element={<WriterPage/>} />
        <Route path="/about-us" element={<About/>} /> 
        <Route path="/contact-us" element={<Contact />} /> 
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />  
        <Route path="/suchen" element={<SearchResults/>} />
    
        <Route path="/allnewsforhome" element={<AllNews/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
