import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';

const About = () => {
  return (
    <Container sx={{ padding: '40px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3, marginTop: '20px', width: '60%', marginBottom: '60px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: '20px',
              color: '#333',
              borderBottom: '2px solid #0288D1',
              width: '180px',
            }}
          >
            Über Uns
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.1rem',
              lineHeight: '1.8',
              color: '#555',
              marginBottom: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Willkommen bei echtzeitnachrichten.de! Wir bringen Ihnen die neuesten und zuverlässigsten Nachrichten aus der ganzen Welt. Unser Team ist engagiert, präzise und aktuelle Informationen zu verschiedenen Themenbereichen wie Politik, Sport, Unterhaltung, Technologie und mehr bereitzustellen.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.1rem',
              lineHeight: '1.8',
              color: '#555',
              marginBottom: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Unsere Mission ist es, Sie mit unvoreingenommener und umfassender Berichterstattung zu informieren. Wir glauben an die Kraft des Wissens und möchten unsere Leser befähigen, mit der Welt verbunden zu bleiben.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: '10px',
              color: '#333',
              borderBottom: '2px solid #0288D1',
              width: '200px',
            }}
          >
            Unsere Vision
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.1rem',
              lineHeight: '1.8',
              color: '#555',
              marginBottom: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Unsere Vision ist es, eine Welt zu schaffen, in der Informationen frei verfügbar, zugänglich und bereichernd sind. Wir streben danach, eine führende Nachrichtenquelle zu sein, die vielfältige Perspektiven bietet, um unseren Lesern zu helfen, fundierte Meinungen zu bilden.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: '10px',
              color: '#333',
              borderBottom: '2px solid #0288D1',
              width: '200px',
            }}
          >
            Unsere Werte
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '1.1rem',
              lineHeight: '1.8',
              color: '#555',
              marginBottom: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            - Integrität: Wir verpflichten uns, ehrliche und transparente Nachrichten bereitzustellen.<br />
            - Genauigkeit: Wir stellen sicher, dass unsere Berichte überprüft und verlässlich sind.<br />
            - Inklusivität: Wir schätzen vielfältige Stimmen und Perspektiven aus aller Welt.<br />
            - Innovation: Wir suchen stets nach neuen Wegen, wie Nachrichten vermittelt und konsumiert werden können.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
