import React from 'react';
import { createTheme} from '@mui/material/styles';
const theme= createTheme({
  typography: {
    h1: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 800,
      fontSize: '40px',
      lineHeight: '52px',
      color: 'rgb(22, 22, 22)',
      fontStyle: 'normal',
    },
    h2: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '28.5px',
      color: 'rgb(80, 80, 80)',
      fontStyle: 'normal',
    },
    subtitle1: {
      fontFamily: 'Outfit, sans-serif',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '28.5px',
      color: 'rgb(80, 80, 80)',
      fontStyle: 'normal',
    },
  },
});


export default theme;
