import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  InputBase,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import logo from "../../assets/logo-w.png"
const BASE_URL = 'https://echtzeitnachrichten.de';

const Header = () => {
  const [activeMenu, setActiveMenu] = useState("HOME");
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState(""); // Search input state

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Mobile check
  const isLaptopOrBigScreen = useMediaQuery('(min-width: 1024px)'); // Laptop or bigger screen

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/api/category/all`);
        const categories = response.data.categories || [];
        const formattedMenuItems = categories.map(item => ({
          name: item.category,
          link: `/categoría/${encodeURIComponent(item.category)}`,
        }));
        setMenuItems(formattedMenuItems);
        setLoading(false);
      } catch (err) {
        setError('Failed to load categories');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent page reload
    if (searchQuery.trim()) {
      navigate(`/suchen?query=${encodeURIComponent(searchQuery)}`); // Navigate to search page with query
      setSearchQuery(""); // Clear search query after submission
    } else {
      alert("Please enter a search query.");
    }
  };

  const handleMenuOpen = () => setDrawerOpen(true);
  const handleMenuClose = () => setDrawerOpen(false);

  if (loading) {
    return <div>Loading menu...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AppBar position="static" color="inherit" elevation={0}>
      <Container sx={{ width: '90%', mx: 'auto' }}>
        <Toolbar sx={{ justifyContent: 'space-between', padding: '10px 0' }}>
          <a href="/">
          <img src={logo} height={"100px"}/></a>

          {/* Search Input - Visible on larger screens only */}
          {!isMobile && (
            <form onSubmit={handleSearchSubmit}>
              <InputBase
                placeholder="Suchen…"
                value={searchQuery} 
                inputProps={{ 'aria-label': 'suchen' }}
                onChange={(e) => setSearchQuery(e.target.value)}
                startAdornment={<InputAdornment position="start"><SearchIcon sx={{ color: 'primary.main' }} /></InputAdornment>}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '50px',
                  padding: '5px 10px',
                  width: '100%',
                  maxWidth: 350,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: '0.3s',
                  '&:focus-within': {
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
                  },
                  '& input': {
                    padding: '5px',
                  }
                }}
              />
            </form>
          )}
        </Toolbar>

        <Box sx={{ height: '3px', backgroundColor: '#282828', width: '95%', mx: 'auto', marginTop: '10px' }} />

        <Toolbar sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {/* All News Button - Always visible on both mobile and laptop screens */}
          <Button
            color="inherit"
            component={Link}
            to="/allnewsforhome"
            sx={{
              fontSize: '12px',
              color: '#fff',
              backgroundColor: '#ff5722',
              padding: '6px 12px',
              borderRadius: '20px',
              '&:hover': { backgroundColor: 'red' },
            }}
          >
            Alle Neuigkeiten
          </Button>

          {/* Display Menu Items and Menu Icon based on screen size */}
          {isLaptopOrBigScreen ? (
            // For Laptop/Big Screens: Show the menu items and menu icon
            <>
              {/* Home Button */}
              <Button
                color="inherit"
                component={Link}
                to="/"
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                  color: activeMenu === 'HOME' ? '#e64a19' : '#282828',
                  textTransform: 'none',
                  '&:hover': { color: '#e64a19' },
                }}
                onClick={() => setActiveMenu('HOME')}
              >
                Heim
              </Button>

              {/* Menu Items */}
              {menuItems.slice(0, 7).map((item, index) => (
                <React.Fragment key={index}>
                  <Box
                    component="li"
                    sx={{
                      listStyle: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      color: '#000',
                      height: '40px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Link
                      to={item.link}
                      onClick={() => setActiveMenu(item.name)}
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        fontFamily: 'Roboto',
                        color: activeMenu === item.name ? '#e64a19' : '#282828',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '25.5px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                    >
                      {item.name}
                    </Link>
                  </Box>
                  {index < menuItems.slice(0, 7).length - 1 && (
                    <Box
                      sx={{
                        height: '20px',
                        width: '1px',
                        backgroundColor: '#282828',
                        alignSelf: 'center',
                      }}
                    />
                  )}
                </React.Fragment>
              ))}

              {/* Menu Icon for opening the Drawer */}
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            // For Mobile: Show Menu Icon
            <>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
            </>
          )}
        </Toolbar>

        {/* Mobile Drawer */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleMenuClose}
          sx={{ width: '50%' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
            <IconButton onClick={handleMenuClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{
                fontWeight: '600',
                fontSize: '12px',
                color: activeMenu === 'HOME' ? '#e64a19' : '#282828',
                textTransform: 'none',
                '&:hover': { color: '#e64a19' },
              }}
              onClick={() => setActiveMenu('HOME')}
            >
              Heim
            </Button>
            {menuItems.map((item, index) => (
              <ListItem button key={index}>
                <Link
                  to={item.link}
                  style={{
                    color: '#000',
                    textDecoration: 'none',
                  }}
                  onClick={handleMenuClose}
                >
                  <ListItemText
                    primary={item.name}
                    sx={{
                      color: '#000',
                      '&:hover': {
                        color: 'red', // Text turns red on hover
                      },
                    }}
                  />
                </Link>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Container>
    </AppBar>
  );
};

export default Header;
