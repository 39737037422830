import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardMedia, CardContent, Container } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom"; // Import Link component
import { truncateText, removeHtmlTags } from "../../helper/utils"; // Import the truncateText function from utils
import { formatDate } from "../../helper/utils"
// Fetching data from the business news API
const fetchBusinessNews = async () => {
  try {
    const response = await axios.get("https://echtzeitnachrichten.de/admin/api/category/news/Finance");
    return response.data; // Ensure response contains `news`
    console.log(response.data)
  } catch (error) {
    console.error("Error fetching business news:", error.response?.data || error.message);
    return { news: [] }; // Return a fallback structure to avoid breaking the component
  }
};

const Business = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBusinessNews();
        console.log(response); // Log response structure
        setNews(response.news || []); // Safely access `news`
      } catch (error) {
        console.error("Error fetching business news:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box sx={{ marginBottom: "40px" }}>
      <Container>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            padding: "0px 16px",
            fontWeight: "bold",
            mb: 2,
            fontSize: "22px",
            margin: "30px 0px",
            position: "relative",
            paddingLeft: "16px",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "4px",
              height: "100%",
              backgroundColor: "red", // Changed to red
              borderRadius: "3px",
            },
            color: "red", // Changed text color to red
          }}
        >
          Finance
        </Typography>

        <Grid container spacing={3}>
          {/* Display the first 4 posts in a 4-column grid */}
          {news.slice(0, 4).map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)", // Scale up on hover
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)", // Add subtle shadow on hover
                  },
                }}
              >
                <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 150,
                      borderRadius: "8px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                    image={item.image || "https://via.placeholder.com/150"}
                    alt={item.title || "No Title"}
                  />
                </Link>
                <CardContent sx={{ padding: "16px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "gray",
                      marginBottom: "5px",
                    }}
                  >
                    {item.category}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                  >
                    <Link to={`/writer/${item.writerName}`} style={{ textDecoration: 'none' }}>
                    Von {item.writerName}
                    </Link>  • {formatDate(item.date)}
                  </Typography>
                  <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#333",
                        marginBottom: "8px",
                      }}
                    >
                      {truncateText(item.title, "title")}
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Business;
