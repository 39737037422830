import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardMedia, Grid, CircularProgress, Container } from "@mui/material";
import axios from "axios";
import { removeHtmlTags, formatDate, truncateText } from "../../helper/utils";
import { Link } from "react-router-dom";
const Voyage = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchVoyageNews = async () => {
            try {
                const response = await axios.get("https://echtzeitnachrichten.de/admin/api/category/news/Voyage");
                setNews(response.data.news.slice(0, 4)); // Limit to 4 items
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch Voyage news. Please try again later.");
                setLoading(false);
            }
        };

        fetchVoyageNews();
    }, []);
    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                    color: "red",
                }}
            >
                <Typography variant="h6">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ backgroundColor: "#f9f9f9", padding: "16px 0" }}>
            <Container>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                        padding: "0px 16px",
                        fontWeight: "bold",
                        mb: 2,
                        fontSize: "22px",
                        margin: "30px 0px",
                        position: "relative",
                        paddingLeft: "16px",
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "4px",
                            height: "100%",
                            backgroundColor: "red", // Changed to red
                            borderRadius: "3px",
                        },
                        color: "red", // Changed text color to red
                    }}
                >
                    Voyage
                </Typography>
                <Grid container spacing={3} justifyContent="center">
                    {news.map((item, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                sx={{
                                    maxWidth: "100%",
                                    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    "&:hover": {
                                        transform: "scale(1.03)",
                                        boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                                    },
                                }}
                            >
                                <Link to={`/${item.slug}`} style={{ textDecoration: 'none' }}>
                                <CardMedia
                                        component="img"
                                        height="140"
                                        image={item.image}
                                        alt={item.title}
                                        sx={{ objectFit: "cover" }}
                                    />

                                </Link>

                            
                                <CardContent sx={{ padding: "12px" }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            color: "gray",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        {item.category}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                                    >
                                        <Link to={`/writer/${item.writerName}`} style={{ textDecoration: 'none' }}>
                                        Von {item.writerName}
                                        </Link>  • {formatDate(item.date)}
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary">
                                        {formatDate(item?.date)}
                                    </Typography>
                                <Link to={`/${item.slug}`} style={{ textDecoration: 'none' }}>
                                    
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#333",
                                                marginBottom: "8px",
                                                fontSize: "14px", // Reduced font size for the heading
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Link>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "#666",
                                            fontSize: "12px", // Reduced font size for the description
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        {removeHtmlTags(item.description)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </Box>
    );
};

export default Voyage;
