import React, {useState, useEffect} from 'react'
import HeroSection from './HeroSection'
import Trending from './Trending'
import Business from './Business'
import Finance from './Finanace'
import NationNews from './NationNews'
import Voyage from './Voyage'
import Medias from './Medias'
import axios from 'axios'
export const Home = () => {
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get(`https://echtzeitnachrichten.de/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          setNewsDetail(response.data);
        } else {
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setError("Failed to fetch news details.");
      });
  }, []); // Empty dependency array ensures the effect runs only once

      useEffect(() => {
        if (newsDetail) {
          // Set the document title
        document.title = newsDetail.meta_title || "Echtzeit Nachrichten";
    
        // Check if the meta title already exists
        let metaTitleTag = document.querySelector('meta[name="title"]');
        if (!metaTitleTag) {
          // Create and append the meta title if it does not exist
          metaTitleTag = document.createElement("meta");
          metaTitleTag.name = "title";
          document.head.appendChild(metaTitleTag);
        }
        metaTitleTag.content = newsDetail.meta_title || "Echtzeit Nachrichten";
    
        // Check if the meta description already exists
        let metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (!metaDescriptionTag) {
          // Create and append the meta description if it does not exist
          metaDescriptionTag = document.createElement("meta");
          metaDescriptionTag.name = "description";
          document.head.appendChild(metaDescriptionTag);
        }
        metaDescriptionTag.content = newsDetail.meta_description || "No description available.";
      }
      }, [newsDetail]);
  return (
    <div>
      <HeroSection/>
      <Trending/>
      <Business/>
      <Finance/>
      <NationNews/>
      <Voyage/>
      <Medias/>

    </div>
  )
}
