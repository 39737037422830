import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import axios from "axios";
import { removeHtmlTags, formatDate, truncateText } from "../../helper/utils";
import { Link } from "react-router-dom";
const fetchMediasNews = async () => {
  try {
    const response = await axios.get("https://echtzeitnachrichten.de/admin/api/category/news/Médias");
    return response.data.news;
  } catch (error) {
    console.error("Error fetching Medias news:", error);
    return [];
  }
};

const Medias = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchMediasNews();
      setNews(fetchedNews.slice(0, 4)); // Limit the news to 4 posts
    };
    getNews();
  }, []);

  return (
    <Box sx={{ padding: "24px 0", backgroundColor: "#f9f9f9", marginBottom: "40px" }}>
      <Container>
        {/* Section Title */}
        <Typography
          variant="h5"
          component="h2"

          sx={{
            padding: "0px 16px",
            fontWeight: "bold",
            mb: 2,
            fontSize: "22px",
            margin: "30px 0px",
            position: "relative",
            paddingLeft: "16px",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "4px",
              height: "100%",
              backgroundColor: "red", // Changed to red
              borderRadius: "3px",
            },
            color: "red", // Changed text color to red
          }}
        >
          Médias
        </Typography>

        <Grid container spacing={4}>
          {news.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  borderRadius: "16px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                  overflow: "hidden",
                }}
              >
                <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>
                <CardMedia
                  component="img"
                  image={item?.image || "https://via.placeholder.com/300"}
                  alt={removeHtmlTags(item?.title)}
                  sx={{ height: 150 }}
                />
                </Link>
                <CardContent sx={{ padding: "16px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "gray",
                      marginBottom: "5px",
                    }}
                  >
                   {item.category}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                  >
                    <Link to={`/writer/${item.writerName}`} style={{ textDecoration: 'none' }}>
                    Von {item.writerName}
                    </Link>  • {formatDate(item.date)}
                  </Typography>
                <Link to={`/${item.slug}`} style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#333",
                    mb: 1,
                  }}
                >
                  {truncateText(removeHtmlTags(item?.title), "title")}
                </Typography>
                </Link>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: "12px" }}
                >
                  {truncateText(removeHtmlTags(item?.description), "description")}
                </Typography>
              </CardContent>
            </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
    </Box >
  );
};

export default Medias;
