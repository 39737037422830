import React, { useEffect, useState } from 'react'; 
import { Button, List, ListItem, ListItemText, Collapse } from '@mui/material';

const TableOfContents = ({ content }) => {
  const [toc, setToc] = useState([]);
  const [activeTocItem, setActiveTocItem] = useState(null);
  const [isTocVisible, setIsTocVisible] = useState(false);

  // Extract headings and assign unique IDs with numbers
  useEffect(() => {
    const extractHeadings = () => {
      const parser = new DOMParser();
      const htmlContent = parser.parseFromString(content, 'text/html');
      const headings = htmlContent.querySelectorAll('h1, h2, h3, h4, h5, h6');
      const tocData = [];
      headings.forEach((heading, index) => {
        const id = `heading-${index + 1}`;
        heading.id = id; // Assign an ID to the heading
        tocData.push({ id, text: heading.textContent, number: index + 1 });
      });
      return tocData;
    };
    setToc(extractHeadings());
  }, [content]);

  // Track active TOC item on scroll
  useEffect(() => {
    const onScroll = () => {
      const headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
      let activeId = null;
      headings.forEach((heading) => {
        const rect = heading.getBoundingClientRect();
        if (rect.top <= 60 && rect.bottom >= 60) {
          activeId = heading.id;
        }
      });
      setActiveTocItem(activeId);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // Smooth scrolling to the selected heading
  const handleTocClick = (id) => {
    const target = document.getElementById(id);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 60, // Adjust for fixed header
        behavior: 'smooth',
      });
    }
  };

  return (
    <div sx={{ position: 'fixed', top: '60px', right: '20px', zIndex: 9999 }}>
      <Button
        size="small"
        onClick={() => setIsTocVisible(!isTocVisible)}
        sx={{
          backgroundColor: isTocVisible ? "#0288D1" : "#9C27B0", // Toggle colors
          color: "#FFFFFF",
          textTransform: "uppercase",
          fontSize: "14px",
          borderRadius: "12px",  // Rounder corners
          padding: "8px 16px",  // Larger padding for better touch area
          marginBottom: "10px",
          marginLeft: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow
          transition: "background-color 0.3s, transform 0.3s",  // Smooth transition
          "&:hover": {
            backgroundColor: isTocVisible ? "#0277BD" : "#7B1FA2",
            transform: "scale(1.05)",  // Slightly enlarge on hover
          },
        }}
      >
        {isTocVisible ? 'Hide TOC' : 'Show TOC'}
      </Button>
      <Collapse in={isTocVisible}>
        <List
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            backgroundColor: 'background.paper',
            boxShadow: 2,
            borderRadius: 1,
            padding: 2,
          }}
        >
          {toc.map((item) => (
            <ListItem
              button
              key={item.id}
              onClick={(e) => {
                e.preventDefault();
                handleTocClick(item.id);
              }}
              sx={{
                padding: "12px 16px",
                borderRadius: "8px", // Rounded corners for list items
                marginBottom: "8px",  // Spacing between items
                transition: "background-color 0.2s, transform 0.2s",
                '&:hover': {
                  backgroundColor: 'action.hover',  // Slightly darker on hover
                  transform: "scale(1.05)",  // Slightly enlarge on hover
                },
                ...(activeTocItem === item.id && {
                  fontWeight: 'bold',
                  color: 'primary.main',
                }),
              }}
            >
              <ListItemText 
                primary={`${item.number}. ${item.text}`}  // Display number and heading text
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default TableOfContents;
