import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Avatar, Container } from "@mui/material";
import axios from "axios";
import { removeHtmlTags, formatDate } from "../../helper/utils";
import { Link } from "react-router-dom";
const fetchBusinessNews = async () => {
  try {
    const response = await axios.get("https://echtzeitnachrichten.de/admin/api/category/news/Divertissement");

    return response.data.news; // Assuming the response contains a 'news' array
  } catch (error) {
    console.error("Error fetching business news:", error);
    return [];
  }
};

const BusinessNews = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const fetchedNews = await fetchBusinessNews();
      setNews(fetchedNews);
    };
    getNews();
  }, []);
  const truncateText = (text, type = 'title') => {
    const maxLength = type === 'title' ? 49 : 400; // Title ke liye max length 45, description ke liye 70

    if (!text) return ''; // Agar text nahi diya gaya, toh empty string return karo

    // Agar text ka length max limit se zyada ho, toh truncate kar do aur "..." add kar do
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }

    return text;
  };

  return (
    <Box sx={{ padding: "24px 0", backgroundColor: "#f9f9f9" }}>
      <Container>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            padding: "0px 16px",
            fontWeight: "bold",
            mb: 2,
            fontSize: "22px",
            margin: "30px 0px",
            position: "relative",
            paddingLeft: "16px",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "4px",
              height: "100%",
              backgroundColor: "red",
              borderRadius: "3px",
            },
            color: "red",
          }}
        >
          Divertissement
        </Typography>
        <Grid container spacing={3}>
          {news[0] && (
            <Grid item xs={12} md={7}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  boxShadow: 4,
                  transition: "transform 0.3s ease",
                  "&:hover": { transform: "scale(1.03)" },
                }}
              >
                <Link to={`/${news[0].slug}`} style={{ textDecoration: "none" }}>
                  <CardMedia
                    component="img"
                    image={news[0]?.image || "https://via.placeholder.com/300"}
                    alt={removeHtmlTags(news[0]?.title)}
                    sx={{ height: 300 }}
                  />
                </Link>
                <CardContent>

                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "gray",
                      marginBottom: "5px",
                    }}
                  >
                    {news[0].category}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                  >
                    <Link to={`/writer/${news[0].writerName}`} style={{ textDecoration: 'none' }}>
                    Von {news[0].writerName}
                    </Link>  • {formatDate(news[0].date)}
                  </Typography>

                  <Typography variant="body2" color="textSecondary">
                    {formatDate(news[0]?.date)}
                  </Typography>
                  <Link to={`/${news[0].slug}`} style={{ textDecoration: "none" }}>

                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ mt: 1, fontWeight: "bold", color: "#333" }}
                    >
                      {truncateText(removeHtmlTags(news[0]?.title), "title")}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    {truncateText(removeHtmlTags(news[0].description), "description")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}

          {/* Small Articles */}
          <Grid item xs={12} md={5}>
            <Grid container spacing={2}>
              {news.slice(1, 5).map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxShadow: 2,
                      padding: 2,
                      transition: "box-shadow 0.3s ease",
                      "&:hover": { boxShadow: 4 },
                    }}
                  >
                    <Link to={`/${news[0].slug}`} style={{ textDecoration: "none" }}>
                      <Avatar
                        src={item?.image || "https://via.placeholder.com/100"}
                        alt={removeHtmlTags(item?.title)}
                        sx={{ width: 72, height: 72, mr: 2 }}
                      />
                    </Link>
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "gray",
                          marginBottom: "5px",
                        }}
                      >
                       {item.category}
                      </Typography>
                      <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "12px", marginTop: "4px", textDecoration: "none" }}
                  >
                    <Link to={`/writer/${item.writerName}`} style={{ textDecoration: 'none' }}>
                    Von {item.writerName}
                    </Link>  • {formatDate(item.date)}
                  </Typography>
                      <Link to={`/${news[0].slug}`} style={{ textDecoration: "none" }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#333" }}>
                          {truncateText(removeHtmlTags(item?.title), "title")}
                        </Typography>
                      </Link>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BusinessNews;
